import Vue from "vue";
import VueRouter from "vue-router";

const axios = () => import("axios");
const Swal = () => import("sweetalert2");

Vue.use(VueRouter);

const Forbidden = () => import("../pages/Forbidden.vue");
import Dashboard from "../pages/Dashboard.vue";
const Login = () => import("../pages/Login.vue");
const Registrasi = () => import("../pages/Registrasi.vue");

const DptList = () => import("../pages/dpt/List.vue");

const PendukungList = () => import("../pages/pendukung/List.vue");
const PendukungDetail = () => import("../pages/pendukung/Detail.vue");
const PendukungManual = () => import("../pages/pendukung/Manual.vue");
const PendukungUpdate = () => import("../pages/pendukung/Update.vue");
const PendukungForm = () => import("../pages/pendukung/Form.vue");

const MasterDapilList = () => import("../pages/master/dapil/List.vue");
const MasterDapilDetail = () => import("../pages/master/dapil/Detail.vue");

const MasterTimList = () => import("../pages/master/tim/List.vue");
const MasterTargetList = () => import("../pages/master/target/List.vue");

const RekapPendukungList = () => import("../pages/rekap/pendukung/List.vue");
const RekapPendukungKecamatanList = () =>
  import("../pages/rekap/pendukung/Kecamatan.vue");

const SaksiList = () => import("../pages/saksi/List.vue");
const SaksiForm = () => import("../pages/saksi/Form.vue");
const SaksiManual = () => import("../pages/saksi/Manual.vue");

const RelawanList = () => import("../pages/relawan/List.vue");
const RelawanForm = () => import("../pages/relawan/Form.vue");

const Profil = () => import("../pages/profil/Profil.vue");

const Pengguna = () => import("../pages/users/List.vue");
const PenggunaForm = () => import("../pages/users/Form.vue");
const PenggunaEdit = () => import("../pages/users/Edit.vue");
const PenggunaPassword = () => import("../pages/users/Password.vue");

const routes = [
  {
    path: "/",
    redirect: "/dashboard/",
  },
  { path: "*", redirect: "/dashboard/" },
  { path: "/forbidden", component: Forbidden },
  { path: "/dashboard", component: Dashboard },
  { path: "/login", component: Login },
  { path: "/registrasi", component: Registrasi },
  { path: "/pengguna", component: Pengguna, meta: { requiredLevel: "admin" } },
  {
    path: "/pengguna/update/:id",
    component: PenggunaEdit,
    meta: { requiredLevel: "admin" },
  },
  {
    path: "/pengguna/password/:id",
    component: PenggunaPassword,
    meta: { requiredLevel: "admin" },
  },
  {
    path: "/pengguna/create",
    component: PenggunaForm,
    meta: { requiredLevel: "admin" },
  },

  { path: "/data-dpt", component: DptList, meta: { requiredLevel: "admin" } },
  {
    path: "/data-pendukung",
    component: PendukungList,
    meta: { requiredLevel: ["admin", "korcam", "kordes", "relawan"] },
  },
  {
    path: "/data-pendukung/manual/",
    component: PendukungManual,
    meta: { requiredLevel: ["admin", "korcam", "kordes", "relawan"] },
  },
  {
    path: "/data-pendukung/detail/:id",
    component: PendukungDetail,
    meta: { requiredLevel: ["admin", "korcam", "kordes", "relawan"] },
  },
  {
    path: "/data-pendukung/dpt/:id",
    component: PendukungForm,
    meta: { requiredLevel: ["admin", "korcam", "kordes", "relawan"] },
  },
  {
    path: "/data-pendukung/update/:id",
    component: PendukungUpdate,
    meta: { requiredLevel: ["admin", "korcam", "kordes", "relawan"] },
  },

  {
    path: "/data-saksi",
    component: SaksiList,
    meta: { requiredLevel: ["admin", "korcam", "kordes"] },
  },
  {
    path: "/data-saksi/manual/",
    component: SaksiManual,
    meta: { requiredLevel: ["admin", "korcam", "kordes"] },
  },
  {
    path: "/data-saksi/set/:id",
    component: SaksiForm,
    meta: { requiredLevel: ["admin", "korcam", "kordes"] },
  },

  {
    path: "/data-relawan",
    component: RelawanList,
    meta: { requiredLevel: ["admin", "korcam", "kordes"] },
  },
  {
    path: "/data-relawan/set/:id",
    component: RelawanForm,
    meta: { requiredLevel: ["admin", "korcam", "kordes"] },
  },
  {
    path: "/master/dapil/",
    component: MasterDapilList,
    meta: { requiredLevel: "admin" },
  },
  {
    path: "/master/dapil/detail/:id",
    component: MasterDapilDetail,
    meta: { requiredLevel: "admin" },
  },
  {
    path: "/master/target/",
    component: MasterTargetList,
    meta: { requiredLevel: "admin" },
  },
  {
    path: "/master/tim/",
    component: MasterTimList,
    meta: { requiredLevel: ["admin", "korcam", "kordes"] },
  },
  {
    path: "/rekap/pendukung/",
    component: RekapPendukungList,
    meta: { requiredLevel: ["admin", "korcam"] },
  },
  {
    path: "/rekap/pendukung/kecamatan/:id",
    component: RekapPendukungKecamatanList,
    meta: { requiredLevel: ["admin", "korcam"] },
  },

  { path: "/profil", component: Profil },
  { path: "/profil/:page", component: Profil },
];

const router = new VueRouter({
  mode: "history",
  // base: "/eregister/",
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem("sipData");
  const userAceess = JSON.parse(isLoggedIn);

  const userLevel = userAceess ? userAceess.level : null;
  const requiredLevel = to.meta.requiredLevel;

  if (to.path !== "/login" && !isLoggedIn && to.path !== "/registrasi") {
    next("/login");
  } else if (to.path === "/login" && isLoggedIn) {
    next("/dashboard");
  } else if (requiredLevel && userLevel && userLevel !== requiredLevel) {
    if (!requiredLevel.includes(userLevel)) {
      next("/forbidden"); // Tambahkan halaman forbidden jika level pengguna tidak sesuai
    } else {
      next();
    }
  } else {
    next();
  }

  const sipData = localStorage.getItem("sipData");
  if (sipData) {
    const user = JSON.parse(sipData);
    const token = user.token;

    // const formData = new FormData();
    // formData.append("token", token);
    axios
      // .post("/api/auth/status/", formData, {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //   },
      // })
      .get("/v1/auth/status/", {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        if (response.data.status === false) {
          Swal.fire({
            title: "Info",
            text: "Session Habis, Silahkan Login Kembali",
            icon: "info",
            showCancelButton: false,
            showConfirmButton: false,
          });
          setTimeout(() => {
            Swal.close();
            localStorage.removeItem("sipData");
            if (to.path !== "/login") {
              next("/login");
            }
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
});

export default router;
