<template>
  <div>
    <button
      class="pull-right btn btn-icon btn-circle btn-light"
      :class="{
        'fa-spin': this.isLoading,
      }"
      @click="fetchData()"
    >
      <i class="fa fa-sync"></i>
    </button>
    <h1 class="page-header">Dashboard <small></small></h1>

    <div class="row">
      <div class="col-lg-3 col-md-6">
        <div class="widget widget-stats bg-red">
          <div class="stats-icon"><i class="fa fa-calendar-check"></i></div>
          <div class="stats-info">
            <h4>DATA TERINPUT HARI INI</h4>
            <p>{{ dataTerinput }}</p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6">
        <div class="widget widget-stats bg-orange">
          <div class="stats-icon"><i class="fa fa-id-badge"></i></div>
          <div class="stats-info">
            <h4>JUMLAH TEMAN OM OYO</h4>
            <p>{{ jumlahRelawan }}</p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6">
        <div class="widget widget-stats bg-grey-darker">
          <div class="stats-icon"><i class="fa fa-users"></i></div>
          <div class="stats-info">
            <h4>JUMLAH TARGET</h4>
            <p>{{ jumlahTarget }}</p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6">
        <div class="widget widget-stats bg-black-lighter">
          <div class="stats-icon"><i class="fa fa-handshake"></i></div>
          <div class="stats-info">
            <h4>JUMLAH PENDUKUNG</h4>
            <p>{{ jumlahPendukung }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div v-if="this.isLoading">
          <div class="bg-light text-center text-info p-40">
            <h3>Loading Grafik</h3>
            <i class="fa fa-circle-notch fa-spin f-s-20"></i>
          </div>
        </div>
        <div ref="barDaily"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import sipData from "../plugins/sipData";
import Highcharts from "highcharts";
import drilldown from "highcharts/modules/drilldown";
import exporting from "highcharts/modules/exporting";
import exportingExcel from "highcharts/modules/export-data";
import accessibility from "highcharts/modules/accessibility";
import seriesLabel from "highcharts/modules/series-label";
import highContrastLight from "highcharts/themes/high-contrast-light";
import highContrastData from "highcharts/modules/data";
import moment from "moment";

drilldown(Highcharts);
exporting(Highcharts);
exportingExcel(Highcharts);
accessibility(Highcharts);
seriesLabel(Highcharts);
highContrastLight(Highcharts);
highContrastData(Highcharts);

export default {
  mixins: [sipData],
  name: "dashboard",
  data() {
    return {
      isLoading: false,
      dataTerinput: 0,
      jumlahRelawan: 0,
      jumlahTarget: 0,
      jumlahPendukung: 0,
      terinputHarian: [],
    };
  },
  mounted() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      this.isLoading = true;
      axios
        .get("/v1/dashboard/")
        .then((response) => {
          this.dataTerinput = response.data.terinput;
          this.jumlahRelawan = response.data.relawan;
          this.jumlahTarget = response.data.target;
          this.jumlahPendukung = response.data.pendukung;
          this.terinputHarian = response.data.tren_terinput;
          this.renderBarDaily();
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
          this.error = error.message;
        });
    },
    renderBarDaily() {
      Highcharts.chart(this.$refs.barDaily, {
        chart: {
          type: "spline",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Timeline Perolehan Dukungan",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        xAxis: {
          type: "category",
        },
        yAxis: {
          title: {
            text: "Data Terinput",
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [
          {
            name: "Data Terinput",
            data: this.terinputHarian.map((data) => ({
              name: this.dateShortFormat(data.tanggal),
              y: parseInt(data.jumlah),
            })),
            colorByPoint: true,
          },
        ],
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ["viewFullscreen", "downloadJPEG", "downloadXLS"],
            },
            menuButtonTextKey: {
              viewFullscreen: "Tampilkan Layar Penuh",
            },
          },
        },
      });
    },

    dateShortFormat(dateTime) {
      return moment(dateTime).format("DD-MM-YY");
    },
  },
};
</script>