export default {
  data() {
    return {
      sipData: null,
      userID: null,
      userFullName: null,
      userName: null,
      userLevel: null,
      userToken: null,
      userPhoto: "../assets/img/user.png",
    };
  },
  created() {
    const sipData = localStorage.getItem("sipData");
    if (sipData) {
      this.sipData = JSON.parse(sipData);
      this.userID = this.sipData.id;
      this.userFullName = this.sipData.nama;
      this.userName = this.sipData.username;
      this.userLevel = this.sipData.level;
      this.userToken = this.sipData.token;
      this.userPhoto = this.sipData.image_profile;
    }
  },
};
