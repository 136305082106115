<script>
const sidebarMenu = [
  { path: "/dashboard", icon: "fa fa-laptop", title: "DASHBOARD" },
  { path: "/data-dpt", icon: "fa fa-users", title: "DATA DPT" },
  { path: "/data-pendukung", icon: "fa fa-file-alt", title: "DATA PENDUKUNG" },
  {
    path: "/data-relawan",
    icon: "fa fa-address-book",
    title: "TEMAN OM OYO",
  },
  { path: "/data-saksi", icon: "fa fa-id-badge", title: "DATA SAKSI" },
  {
    path: "/rekap",
    icon: "fa fa-file-pdf",
    title: "REKAPITULASI",
    children: [
      { path: "/rekap/pendukung", title: "REKAP PENDUKUNG" },
      // { path: "/rekap/saksi", title: "REKAP SAKSI" },
    ],
  },
  {
    path: "/master",
    icon: "fa fa-book",
    title: "MASTER DATA",
    children: [
      { path: "/master/tim", title: "DATA TIM OM OYO" },
      { path: "/master/dapil", title: "DATA DAPIL" },
      { path: "/master/target", title: "DATA TARGET" },
    ],
  },
  {
    path: "/pengguna",
    icon: "fa fa-user",
    title: "USER MANAGEMENT",
  },
];

export default sidebarMenu;
</script>
